/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-telegram': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M9.353.75C4.4.75.387 4.666.387 9.5s4.013 8.75 8.966 8.75c4.954 0 8.967-3.916 8.967-8.75S14.307.75 9.353.75zm4.404 5.994l-1.471 6.768c-.109.48-.402.596-.81.37L9.234 12.27l-1.081 1.016c-.12.116-.22.215-.452.215l.16-2.226 4.153-3.663c.181-.155-.04-.243-.278-.088l-5.134 3.154-2.213-.674c-.48-.148-.492-.469.101-.695l8.645-3.253c.402-.14.752.096.622.688z" _fill="#fff" fill-opacity=".4"/>'
  }
})
